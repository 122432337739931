
p#texto{
    padding-top: 10px;;
	text-align: center;
	color:#959ba7;
    font-size: 30px;
}

div#div_file{
	display: flex;
	align-items: center;
	justify-content: center;
	position:relative;
	padding:10px;
	width:225px;
	height: 140px;
	background-color: #eeeff1;
	border-radius:5px;
    border: solid .5px #959ba7;
}
div#div_file
input#btn_enviar{
	position:absolute;
	top:0px;
	left:0px;
	right:0px;
	bottom:0px;
	width:100%;
	height:100%;
	opacity: 0;
}
.div_imagen{
	position: relative;
	padding: 0;
	width: 225px;
	background-color: #eeeff1;
	border-radius: 5px;
	border: none;
	height: 140px;
	margin-right: 10px;
}
.div_imagen img{
	width: 100%;
	height: 140px;
	border-radius: 5px;
}
p#texto_btn{
    padding-top: 12px;
	text-align: center;
	color:#fff;
    font-size: 15px;
	font-weight: 100;
}
div#div_file_btn{
	position:relative;
	margin:5px;
	padding:3px;
	width:140px;
	background-color: #33383b;
	border-radius:5px;
    border: solid .5px #959ba7;
}
div#div_file_btn
input#btn_enviar{
	position:absolute;
	top:0px;
	left:0px;
	right:0px;
	bottom:0px;
	width:100%;
	height:100%;
	opacity: 0;
}
.contenedor {
    background-color: #f6f7fb;
    height: auto;
    padding-bottom: 40px;
}
.accordion-button:not(.collapsed) {
    color: black;
    background-color: #e7f1ff;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
	background-color: #FFF;
}
.accordion-button:not(.collapsed)::after {
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e');
    transform: rotate(-180deg);
}
.accordion-button:focus {
    z-index: 3;
    border-color: #FFF!important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 25%);
}
.accordion-item {
    background-color: #fff;
    border: 1px solid #fff;
}
.badge-publiched{
	border-style: none;
	background-color: #c4af6c !important;
	color: #fff!important;
	font-weight: 500!important;
	box-shadow: 0.1px 2px 10px rgba(0, 0, 0, 0.1);
}
.badge-publiched:hover{
	background-color: #a8965c !important;
}
.badge-publiched-vip{
	background-color: #33383b!important;
	color: #c4af6c!important;
}
.badge-nopubliched:hover{
	background-color: #afafaf!important;
}

/*PROGRESSBAR*/
.stepper-wrapper {
	margin-top: auto;
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}
.stepper-item {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;

	@media (max-width: 768px) {
		font-size: 10px;
	}
}

.stepper-item::before {
	position: absolute;
	content: "";
	border-bottom: 2px solid #fff;
	width: 100%;
	top: 20px;
	left: -50%;
	z-index: 2;
}

.stepper-item::after {
	position: absolute;
	content: "";
	border-bottom: 2px solid #fff;
	width: 100%;
	top: 20px;
	left: 50%;
	z-index: 2;
}

.stepper-item .step-counter {
	position: relative;
	z-index: 5;
	display: flex;
	justify-content: center;
	/* align-items: center; */
	line-height: 30px;
	width: 30px;
	height: 30px;
	color: #fff;
	border-radius: 50%;
	background: #ddd;
	font-weight: bold;
	border: solid 3px #fff;
}

.stepper-item.active {
	font-weight: bold;
	border: solid 3px #fff;
}

.stepper-item.completed .step-counter {
	border: solid 3px #fff;
	background-color: #c4af6c;
	font-weight: bold;
}
/*liena de progreso*/
.stepper-item.completed::after {
	position: absolute;
	content: "";
	border-bottom: 2px solid #c4af6c;
	width: 100%;
	top: 15px;
	left: 50%;
	z-index: 3;
}

.stepper-item:first-child::before {
	content: none;
}
.stepper-item:last-child::after {
	content: none;
}
.img-container{
	width: 225px !important;
	height: 140px !important;
}
.img-thumb-send{
	width: 225px !important;
	height: 140px !important;
}
.overlay {
	position: absolute; 
	bottom: 5; 
	background: rgb(0, 0, 0);
	background: rgba(0, 0, 0, 0.5); /* Black see-through */
	color: #f1f1f1; 
	width: 225px;
	transition: .5s ease;
	opacity:0;
	color: white;
	font-size: 20px;
	padding: 30px 60px 106px 20px;
	text-align: center;
}
.overlay-added {
	position: absolute; 
	bottom: 5; 
	color: #f1f1f1; 
	width: 225px;
	color: #33383b;
	font-size: 20px;
	padding: 30px 60px 106px 20px;
	text-align: center;
	font-size: xx-large;
}
.in-overlay-added{
	width: fit-content;
    border-radius: 50%;
}
.overlay-added .in-overlay-added{
	font-size:xx-large;
	position: relative;
    display: flex;
    flex-direction: row-reverse;
    left: 110%;
    margin-top: -10%;
    align-items: center;
	font-weight:bold;
	background-color: #c4af6c;
	text-shadow: 2px 2px #33383b;
}
.overlay .in-overlay{
	
	position: relative;
    display: flex;
    flex-direction: row-reverse;
    left: 30%;
    margin-top: -10%;
    align-items: center;
	
}

.img-container:hover .overlay {
	opacity: 1;
}
.added{
	border: solid 3px #c4af6c;
}
/*delete image overlay*/
.overlay-delete {
	position: absolute; 
	bottom: 5; 
	color: #fff; 
	width: 225px;
	/* color: #33383b; */
	font-size: 20px;
	padding: 30px 60px 106px 20px;
	text-align: center;
	font-size: xx-large;
}
.in-overlay-delete{
	width: fit-content;
    border-radius: 50%;
}
.overlay-delete .in-overlay-delete{
	font-size:xx-large;
	position: relative;
    display: flex;
    flex-direction: row-reverse;
    left: 110%;
    margin-top: -10%;
    align-items: center;
	font-weight:bold;
	/* background-color: tomato; */
	text-shadow: 2px 2px tomato;
}
.delete{
	border: solid 3px tomato;
}
.form-check-input:checked {
    background-color: #C7AD67!important;
    border-color: #C7AD67!important;
    color: #33383b !important;
}
.form-check-input:focus{
    box-shadow: 0 0 0 1px #C7AD67!important;
    color: #33383b !important;
}
.form-check-input::selection{
    box-shadow: 0 0 0 1px #C7AD67!important;
    color: #33383b !important;
}
input[type=number]::-webkit-inner-spin-button {
	-webkit-appearance: none;
}
table.dataTable thead th, table.dataTable thead td {
    padding: 10px 18px;
    border-bottom: 1px solid #CCC !important;
}
.contenido .header h1{
    font-size: 1.9rem;
}
.css-t3ipsp-control {
	border-color: #dee2e6 !important;
	box-shadow: 0 0 0 1px #dee2e6!important;
}
.css-t3ipsp-control:hover {
	box-shadow: 0 0 0 1px #dee2e6 !important;
}
.css-t3ipsp-control::selection {
	border-color: #dee2e6 !important;
	box-shadow: 0 0 0 1px #dee2e6!important;
}
