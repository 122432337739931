.breadcrumb-item + .breadcrumb-item::before {
  content: ">" !important;
}
.breadcrumb-item,
.breadcrumb-item.active {
  font-size: 12px;
  color: #b6b8bc;
}
.dataTables_filter {
  display: none;
}
.topline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

tbody:before {
  /* This doesn't work because of border-collapse */
  line-height: 1em;
  content: "_";
  color: white;
  display: block;
}

.middleline {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
  margin: 10px 0;
  align-items: center;
  gap: 16px;
}

.buscador {
    flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buscador input {
  background-color: #edeff1;
  height: 46px;
  margin-right: 10px;
}
.filtros {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.filtros select {
  word-wrap: normal;
  width: 100%;
  margin: 10px 10px 0px auto;
  height: 46px;
  background-color: #edeff1;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

/* Clearable text inputs */
.clearable {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-right: 10px;
}
.clearable input[type="text"] {
  padding-right: 24px;
  width: 100%;
  box-sizing: border-box;
  word-wrap: normal;
  width: 100%;
  margin: 0px 10px 0px auto;
  height: 46px;
  background-color: #edeff1;
  border: 1px solid #ced4da;
  border-radius: 5px;
}
.clearable__clear {
  display: none;
  position: absolute;
  right: 0;
  top: 15px;
  padding: 0 8px;
  font-style: normal;
  font-size: 1.2em;
  user-select: none;
  cursor: pointer;
}
.clearable input::-ms-clear {
  /* Remove IE default X */
  display: none;
}

/* ------------------- FICHA CLIENTE  ------------------   */
.ficha-cliente .usuario {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ficha-cliente .topline svg {
  vertical-align: top;
}

.contenido {
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
  margin: 10px 0;
  border-radius: 5px;
}
.contenido .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contenido .header .editar {
  width: 35px;
  height: 35px;
}
.contenido .info-cliente table {
  width: 500px;
  text-align: left;
  font-size: 14px;
}
.contenido .info-cliente table tr {
  border: transparent;
}

/* .contenido .info-inmueble {
    
    display: flex;
    align-items: center;
    justify-content: space-between;
} */
.contenido .info-inmueble .card {
  text-align: left;
  margin: 10px;
  border: none;
  width: 250px;
  display: inline-block;
}
.contenido .info-inmueble .card-body {
  padding-left: 0px;
  padding-right: 0px;
}
.contenido .info-inmueble .card img {
  width: 100%;
  border-radius: 5px;
}

/* .card-box {
    border: 1px solid #ddd;
    padding: 20px;
    box-shadow: 0px 0px 10px 0px #c5c5c5;
    margin-bottom: 30px;
    float: left;
    border-radius: 10px;
} */
/* .card-box .card-thumbnail {
    max-height: 200px;
    overflow: hidden;
    border-radius: 10px;
    transition: 1s;
} */
/* .card-box .card-thumbnail:hover {
    transform: scale(1.2);
} */
.card-box .card-thumbnail img {
  border-radius: 5px;
}
.card-box h3 a {
  font-size: 20px;
  text-decoration: none;
}

.contenido-documentos .header,
.contenido-documentos .header .descargar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contenido-documentos .header {
  margin: 20px 0;
}
.contenido-documentos .header .descargar button,
.info-documentos table button {
  padding: 0;
  width: 32px;
  height: 30px;
}
.info-documentos table tbody tr,
.info-documentos table thead tr {
  background-color: #fff;
}
.info-documentos table td:last-child {
  text-align: center;
}
.errFile {
  border: solid crimson !important;
}
.errText {
  color: crimson !important;
}
.errData {
  border: solid crimson !important;
}
.form-check-input:checked {
  background-color: #c7ad67 !important;
  border-color: #c7ad67 !important;
  color: #33383b !important;
}
.form-check-input:focus {
  box-shadow: 0 0 0 1px #c7ad67 !important;
  color: #33383b !important;
}
.form-check-input::selection {
  box-shadow: 0 0 0 1px #c7ad67 !important;
  color: #33383b !important;
}
