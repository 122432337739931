@font-face {
  font-family: Forum;
  src: url('../src/fonts/Forum-Regular.ttf');
}
@font-face {
  font-family: Spartan;
  src: url('../src/fonts/LeagueSpartan-VariableFont_wght.ttf');
}

body {
  margin: 0;
  font-family: 'Spartan', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,h2,h3,h4,h5,h6{
  font-family: 'Forum';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-white {background-color: #fff;}
.bg-primario {background-color: #33383b;}
.bg-primario:hover, .bg-primario:focus, .bg-primario:active{color:#232627}
.bg-secundario {background-color: #c4ad6c;}
.bg-secundario:hover, .bg-secundario:focus, .bg-secundario:active{color:#aa9456}
.color-primario{color:#33383b}
.color-primario:hover, .color-primario:focus, .color-primario:active{color:#232627}
.color-secundario{color:#c4ad6c}
.color-secundario:hover, .color-secundario:focus, .color-secundario:active{color:#aa9456}
.br-5{border-radius: 5px;}
.form-content{max-width: 500px;}
a{text-decoration: none;}
a:hover, a:focus, a:active{text-decoration: underline;}
.btn-primario{
  background-color: #33383b;
  color:#fff;
  border-color: #232627;
}
.btn-primario:hover{
  background-color: #232627;
  color:#fff;
  border-color: #33383b;
}
.btn-dark{
  height: 36px;
  padding: 0 15px;
}
.btn-dark-filter{
  height: 46px;
  padding: 0 40px;
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-dark-filter:hover {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
}
.btn-white{
  height: 46px;
  padding: 0 25px;
  background: #fff;
  color: #212529;
  border-color: #212529;
}
.btn-form, .btn-form:hover, .btn-form:focus, .btn-form:active, .btn-form:active:focus{
  background-color: #edeff1;
  height: 45px;
  color: #6c757d;
  border: 1px solid #ced4da;
  box-shadow: none;
}
.btn-default{
  padding: 0;
  width: 36px;
  height: 36px;
  position: relative;
  border: 1px solid #b0b0b0;
  color: gray;
  background-color: #fff;
}
.btn-default:hover, .btn-default:focus, .btn-default:active{
  border: 1px solid #c4af6c;
  color: #33383b;
  background-color: #c4af6c;
}
.btn-head{
  width:35px;
  height: 35px;
  margin:5px
}
.btn-head svg{
  font-size: 25px;
  vertical-align: middle;
}
.text-left{
  text-align: left;
}
.text-center{
  text-align: center;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button{
  border-radius: 50%;
  padding: 0 5px;
  font-size: 14px;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current{
  background: #c4af6c;
  color: #fff !important;
  border: 1px solid #c4af6c;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover, .dataTables_wrapper .dataTables_paginate .paginate_button:hover{
  background: #33383b;
  color: #fff !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.next, .dataTables_wrapper .dataTables_paginate .paginate_button.previous{
  border-radius: 5px;
  padding: 0 5px;
  font-size: 14px;
}

table.dataTable {
  border-collapse: collapse;
}
table.dataTable thead{
  background:#fff;
}

table.dataTable.no-footer {
  border-bottom: 1px solid #dee2e6;
}
.table > :not(:first-child) {
  border-top: 1px #dee2e6;
}

.table-bordered > :not(caption) > * {
  border-bottom-style: double;
}
.dataTables_wrapper .dataTables_paginate {
  margin-top: 1rem;
}
table.dataTable button{
  color: gray;
background: #ccc;
padding: 5px;
height: 28px;
width: 28px;
display: flex;
margin: 0 2px;
}

table.dataTable .botones{
  display: flex;
  margin: 7px 15px;
  justify-content: center;
}
table.dataTable tbody th, table.dataTable tbody td {
  padding: 10px 10px;
  border-bottom: 0.1px solid #CCC;
  vertical-align: middle;
}

.back{
  background: #fff;
  border-radius: 50%;
  margin-right: 20px;
  height: 36px;
  width: 36px;
  border: 1px solid #ccc;
  padding: 6px 0px;
  font-size: 20px;
}


.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active:hover {
  color: #495057;
  background-color: transparent;
  border-color: transparent;
  border-bottom: 3px solid gray;
  font-size: 12px;
  cursor: auto;
}
.nav-link {
  font-size: 12px;
  color: #b8b5b5;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: transparent;
  isolation: isolate;
  color: #495057;
  text-decoration: none;
  cursor: pointer;
}

.modal input, .modal select, .modal textarea, .formulario input, .formulario select, .formulario textarea{
  background-color: #edeff1;
  height: 45px;
}
.modal .form-label{
  font-size: 12px;
}
.modal-body .row{
  align-items: center;
  justify-content: center;
}
.formulario{text-align: left;}
.modal .form-check input, .formulario .form-check input{
  width:3rem;
  margin-right: 10px;
}
.modal .form-check, .formulario .form-check{
  display: flex;
  align-items: center;
}
.modal .check-align, .formulario .check-align{
  display: flex;
  align-items: flex-end;
}

.modal .form-check input:checked, .formulario .form-check-input:checked {
  background-color: #1c1f23;
  border-color: #1c1f23;
}
.filesSelect{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #edeff1;
  height: 150px;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  border: dashed 1px #ccc;
}
.filesSelect .bntDiv{
  position: relative;
}
.filesSelect .bntDiv span{
  vertical-align: -webkit-baseline-middle;
}
.filesSelect input[type="file"]{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  cursor: pointer;
  filter: alpha(opacity=0);
  opacity: 1;
  background-color: red;
}

#clientesTable_wrapper, #inmueblesTable_wrapper{
  overflow-x: scroll;
}
.dropzone{
  width: 100%;
  padding: 30px;
}